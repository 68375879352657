<template>
  <base-section
    id="kurse"
    class="secondary"
  >
    <v-responsive
      class="mx-auto"
      max-width="1350"
    >
      <base-section-heading
        class="primary--text gap"
        color="transparent"
        title="BREAKING KURSE"
      />

      <v-container
        fluid
        class="pl-md-12"
      >
        <v-row class="flex-row">
          <v-col
            cols="12"
            sm="6"
            md="6"
            class=" pr-10 flex-col"
          >
            <h3 class="text-uppercase text-h5 pb-5 primary--text">
              FREIES TRAINING
            </h3>
            <p class="accent--text text-none text-h6 mb-6">
              Das freie Training wird von einem Coach begleitet. Er bietet dir Hilfestellung und ist da für dich wenn du Fragen hast.
              Du trainierst selbständig an deinen Skills und vertiefst dich in der Praxis.<br><br>

              Dienstags jeweils von 16:30 – 18:30 (2h)<br>
              Jugendkulturhaus Dynamo
            </p>
          </v-col>
          <v-col
            cols="12"
            sm="6"
            md="6"
            class="pr-10 "
          >
            <h3 class="text-uppercase text-h5 pb-5 primary--text">
              UNTERRICHTS&#x200b;STUNDEN
            </h3>
            <p class="accent--text text-none text-h6 mb-6">
              Der Unterricht findet wöchentlich während dem Schuljahreskalender statt.
              In den Schulferien finden keine Regelkurse statt.
            </p>
          </v-col>
          <!--v-col
            cols="12"
            sm="4"
            md="4"
            class=" pr-10 "
          >
            <h3 class="text-uppercase text-h5 pb-5 primary--text">
              OPEN PRACTICE
            </h3>
            <p class="accent--text text-none text-h6 mb-6">
              Lorem Ipsum Dolor sit amet
            </p>
          </v-col-->
        </v-row>
        <v-row>
          <v-col>
            <v-card
              class="mb-12"
            />

            <div class="text-center">
              <base-btn
                :tile="false"
                color="tertiary"
                rounded
              >
                <router-link
                  class="black--text"
                  to="/preise"
                  style="text-decoration: none"
                >
                  Preise
                </router-link>
              </base-btn>
            </div>
          </v-col>
        </v-row>
      </v-container>
    </v-responsive>
  </base-section>
</template>

<script>
  export default {
    name: 'SectionFeatures',
  }
</script>
<style scoped>
.v-application .text-h6{
  font-weight: 700;
}
.gap{line-height: 5rem;}
</style>
